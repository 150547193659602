import React from "react"
import { graphql } from "gatsby"
import ArchiveCore from "../components/ArchiveCore"

const Archive = (props) => {
  return(
    <ArchiveCore props={props} />
  )
}


export const query = graphql`
query ArchiveBlog($offset: Int, $perPage: Int, $slug: String, $nodeType: String, $uri: String) {
    wp {
      generalSettings {
        title
      }
    }
    allWpPost(
      limit: $perPage,
      skip: $offset,
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}, nodeType: {eq: $nodeType}}}}},
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        slug
        uri
        title
        featuredImage {
          node {
            altText
            mediaDetails {
              sizes {
                file
                height
                width
                sourceUrl
                name
              }
            }
          }
        }
        excerpt
        categories {
          nodes {
            uri
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }

  wpCategory(uri: {eq: $uri}) {
    seo {
      metaKeywords
      title
      opengraphUrl
      opengraphImage {
        link
        mediaDetails {
          height
          width
        }
      }
      opengraphDescription
      twitterDescription
      twitterImage {
        link
      }
    }
    description
    name
    slug
    nodeType
  }
  wpTag(uri: {eq: $uri}) {
    seo {
      metaKeywords
      title
      opengraphUrl
      opengraphImage {
        link
        mediaDetails {
          height
          width
        }
      }
      opengraphDescription
      twitterDescription
      twitterImage {
        link
      }
    }
    description
    name
    slug
    nodeType
  }
}
`

export default Archive
